import * as React from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import Tooltip from '@amzn/awsui-components-react/polaris/tooltip';
import strings from './strings';
import './header.scss';
import { ArgoContext, useArgoContext } from '../../index';

const ACCOUNT_OPTIONS = [
  {
    id: 'account',
    text: 'Account',
    items: [
      {
        id: 'account.permissions',
        disabled: true,
        text: 'My Permissions',
      },
      {
        id: 'account.subscriptions',
        disabled: true,
        text: 'My Subscriptions',
      },
      {
        id: 'account.sign-out',
        disabled: false,
        text: 'Sign Out',
      },
    ],
  },
  {
    id: 'preference',
    text: 'Preferences',
    disabled: true,
    items: [
      {
        id: 'preference.language',
        text: 'Language',
      },
      {
        id: 'preference.time-zone',
        text: 'Time Zone',
      },
      {
        id: 'preference.notification',
        text: 'Notification',
      },
    ],
  },
];

interface Props {
  context: ArgoContext;
}

export function HeaderWithProps(props: Props) {
  const { context } = props;
  const onButtonDropdownClick = (e: CustomEvent<ButtonDropdown.ItemClick>) => {
    if (e.detail.id === 'account.sign-out') {
      context.user.signOut();
    }
  };

  return (
    <header className='argo-header' data-cy='argo-header'>
      <ul className='flex-box-align-items-center-and-justify-content-space-between menu-list'>
        <li className='argo-logo'>{strings.title}</li>
        <li>
          <ul className='flex-box'>
            <li className='argo-header-icons'>
              <Tooltip text={strings.comingSoon} position='bottom'>
                <Button variant='icon' icon='notification' />
              </Tooltip>
            </li>
            <li>
              <ButtonDropdown
                items={ACCOUNT_OPTIONS}
                text={strings.welcome(context.user.username)}
                onItemClick={onButtonDropdownClick}
              />
            </li>
          </ul>
        </li>
      </ul>
    </header>
  );
}

export function HeaderWithContext() {
  return <HeaderWithProps context={useArgoContext()} />;
}
