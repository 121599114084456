import { Button, Icon, Modal } from "@amzn/awsui-components-react";
import strings from "./strings";
import React from "react";
import '@amzn/awsui-components-react/index.min.css';
import './legalAgreement.scss';

interface LegalAgreementModalProps {
    onSubmitContinue: EventListener
    siteTermsText: string;
}

export const LegalAgreementModal = (props: LegalAgreementModalProps) => {
    return (
        <Modal
            visible={true}
            header="Site Use"
            footer={<span className="awsui-util-f-r">
                <Button variant="primary" onClick={props.onSubmitContinue}>Continue</Button>
            </span>}
            onDismiss={(event) => { event.preventDefault(); }}
            className="argo-legal-agreement-modal"
        >
            By continuing, you agree to the {' '}
            <a target="_blank" href={strings.privacyPolicyLink} className='awsui-text-info-link' rel="noopener noreferrer">
                {strings.privacyPolicyText} <Icon variant='link' name='external' />
            </a>{' '}
            and the following:
            {<span>{<div dangerouslySetInnerHTML={{ __html: props.siteTermsText }}></div>}</span>}
        </Modal>
    );
}