import * as React from 'react';

import { FrameworkStage, FrontEndFeatureSet } from './ArgoEnums';
import ErrorBoundary from './ui/errorBoundary/ErrorBoundary';
import { getArgoConfig } from './window';
import { get as _get } from 'lodash';
import * as LoggerUtility from './utils/logger/utility';
import DevApp from './ui/argoApp/devApp';
import CognitoApp from './ui/argoApp/cognitoApp';
import { AuthConfig, ArgoAppProps } from './ArgoApp.types';

const browserName = LoggerUtility.getUserBrowserName();
const deviceType = LoggerUtility.getUserDeviceType();
const deviceOS = LoggerUtility.getUserDeviceOS();
const locale = LoggerUtility.getUserLocale();

let applicationVisitId = '';
let argoSessionId = '';

function getApplicationName(config: any): string {
  const appName = _get(config, 'argo.applicationName', 'Argo');
  return appName;
}

function getCellNumber(config: any): string {
  const cell = _get(config, 'argo.cell', 'cell-0');
  return cell;
}

function getArgoHadesUrl(config: any): string {
  const argoHadesUrl = _get(config, 'argo.argoHadesUrl');
  return argoHadesUrl;
}

function getKatalLoggerUrl(config: any): string {
  const katalLoggerUrl = _get(config, 'argo.katalLoggerUrl');
  return katalLoggerUrl;
}

function getAuthManagerEnabled(config: any): boolean {
  const authManagerEnabled = _get<boolean>(config, 'argo.authManagerEnabled', false);
  return authManagerEnabled;
}

function getFrameworkStage(config: any): FrameworkStage {
  const frameworkStage = _get<FrameworkStage>(
    config,
    'argo.frameworkStage',
    FrameworkStage.prod
  );
  if (frameworkStage in FrameworkStage) {
    return frameworkStage;
  }
  return FrameworkStage.prod;
}

function getFrontEndFeatureSet(config: any): FrontEndFeatureSet {
  const frontEndFeatureSet = _get<FrontEndFeatureSet>(
    config,
    'argo.frontEndFeatureSet',
    FrontEndFeatureSet.Release
  );
  if (frontEndFeatureSet in FrontEndFeatureSet) {
    return frontEndFeatureSet;
  }
  return FrontEndFeatureSet.Release;
}

export const ArgoApp = (props: ArgoAppProps) => {
  const config = getArgoConfig();
  const frameworkStage = getFrameworkStage(config);
  const frontEndFeatureSet = getFrontEndFeatureSet(config);
  const appName = getApplicationName(config);
  const cell = getCellNumber(config);
  const katalLoggerUrl = getKatalLoggerUrl(config);
  const argoHadesUrl = getArgoHadesUrl(config);
  const authManagerEnabled = getAuthManagerEnabled(config);
  const userName = _get<string>(config, 'argo.user.username', 'Developer');
  // Use the cognito config from props if it exists, then fall back to ArgoConfig
  const cognitoConfig = props.authConfig || _get<AuthConfig | undefined>(config, 'argo.cognito', undefined)

  function onApplicationVisitIdChange(id: string): void {
    applicationVisitId = id;
  }

  function onArgoSessionIdChange(id: string): void {
    argoSessionId = id;
  }

  if (cognitoConfig) {
    return (
      <ErrorBoundary
          appName={appName}
          region={cognitoConfig.region}
          frameworkStage={frameworkStage}
          cell={cell}
          katalLoggerUrl={katalLoggerUrl}
          argoHadesUrl={argoHadesUrl}
          browserName={browserName}
          deviceType={deviceType}
          deviceOS={deviceOS}
          locale={locale}
          applicationVisitId={applicationVisitId}
          argoSessionId={argoSessionId}>
        <CognitoApp
          cognito={cognitoConfig}
          region={cognitoConfig.region}
          content={props.content}
          frameworkStage={frameworkStage}
          frontEndFeatureSet={frontEndFeatureSet}
          appName={appName}
          cell={cell}
          katalLoggerUrl={katalLoggerUrl}
          argoHadesUrl={argoHadesUrl}
          authManagerEnabled={authManagerEnabled}
          browserName={browserName}
          deviceType={deviceType}
          deviceOS={deviceOS}
          locale={locale}
          applicationVisitId={applicationVisitId}
          argoSessionId={argoSessionId}
          onApplicationVisitIdChange={onApplicationVisitIdChange}
          onArgoSessionIdChange={onArgoSessionIdChange}
        />
      </ErrorBoundary>
    );
  } else {
    const customGroups = config && config.argo && config.argo.custom_groups;
    return (
      <ErrorBoundary
          appName='argo-test'
          region='us-west-2'
          frameworkStage='dev'
          cell='cell-0'
          katalLoggerUrl={katalLoggerUrl}
          argoHadesUrl={argoHadesUrl}
          browserName={browserName}
          deviceType={deviceType}
          deviceOS={deviceOS}
          locale={locale}
          applicationVisitId={applicationVisitId}
          argoSessionId={argoSessionId}>
        <DevApp
          content={props.content}
          customGroups={customGroups}
          frameworkStage={frameworkStage}
          frontEndFeatureSet={frontEndFeatureSet}
          userName={userName}
        />
      </ErrorBoundary>
    );
  }
};
