// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react';
import { MutableRefObject, useCallback, useRef } from 'react';
import {
  Transition as ReactTransitionGroupTransition,
  TransitionStatus as ReactTransitionGroupTransitionStatus,
} from 'react-transition-group';
import { isMotionDisabled } from '../../motion';

export type TransitionStatus = ReactTransitionGroupTransitionStatus | 'enter' | 'exit';

export interface TransitionProps {
  in: boolean;
  exit?: boolean;

  disabled?: boolean;

  children: (
    state: ReactTransitionGroupTransitionStatus,
    transitioningElementRef: MutableRefObject<any>
  ) => React.ReactNode;

  onStatusChange?: (status: TransitionStatus) => void;
}

/**
 * This component is a wrapper around the CSSTransition component.
 *
 * It provides a second parameter in its rendering function that must be
 * attached to the node that is transitioning.
 */
export function Transition({
  in: isIn,
  children,
  exit = true,
  onStatusChange = () => void 0,
  disabled = false,
}: TransitionProps) {
  const transitionRootElement = useRef<HTMLElement>(null);

  const motionDisabled =
    disabled || (transitionRootElement.current ? isMotionDisabled(transitionRootElement.current) : true);

  const addTransitionEndListener = useCallback((done: () => void) => {
    const node = transitionRootElement.current;

    if (node === null) {
      return;
    }

    const listener = (e: TransitionEvent | AnimationEvent) => {
      if (e.target === node) {
        node.removeEventListener('transitionend', listener);
        node.removeEventListener('animationend', listener);
        done();
      }
    };

    node.addEventListener('transitionend', listener);
    node.addEventListener('animationend', listener);
  }, []);

  return (
    <ReactTransitionGroupTransition
      timeout={motionDisabled ? 0 : undefined}
      in={isIn}
      addEndListener={addTransitionEndListener}
      nodeRef={transitionRootElement}
      exit={exit}
      onEnter={isAppearing => !isAppearing && onStatusChange('enter')}
      onEntering={isAppearing => !isAppearing && onStatusChange('entering')}
      onEntered={isAppearing => !isAppearing && onStatusChange('entered')}
      onExit={() => onStatusChange('exit')}
      onExiting={() => onStatusChange('exiting')}
      onExited={() => onStatusChange('exited')}
    >
      {state => children(state, transitionRootElement)}
    </ReactTransitionGroupTransition>
  );
}
