// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useContext } from 'react';
import clsx from 'clsx';
import { AppLayoutContext } from './context';
import { SplitPanelContext } from '../../internal/context/split-panel-context';
import styles from './styles.css.js';
import testutilStyles from '../test-utils/styles.css.js';

export default function Main() {
  const {
    breadcrumbs,
    content,
    contentHeader,
    contentType,
    disableContentPaddings,
    hasNotificationsContent,
    isNavigationOpen,
    isSplitPanelOpen,
    isToolsOpen,
    isMobile,
    isAnyPanelOpen,
    mainElement,
  } = useContext(AppLayoutContext);

  const { position: splitPanelPosition } = useContext(SplitPanelContext);
  const isUnfocusable = isMobile && isAnyPanelOpen;

  return (
    <div
      className={clsx(styles.container, testutilStyles.content, { [styles.unfocusable]: isUnfocusable })}
      data-content-type={contentType}
      data-disable-content-paddings={disableContentPaddings ? true : false}
      data-has-breadcrumbs={breadcrumbs ? true : false}
      data-has-header={contentHeader ? true : false}
      data-has-notifications-content={hasNotificationsContent ? true : false}
      data-is-navigation-open={isNavigationOpen ? true : false}
      data-is-tools-open={isToolsOpen ? true : false}
      data-is-split-panel-open={isSplitPanelOpen ? true : false}
      data-split-panel-position={splitPanelPosition ?? 'bottom'}
      ref={mainElement}
    >
      {content}
    </div>
  );
}
