import React, { useState, useEffect } from 'react';
import strings from './strings';
import { LegalAgreementModal } from '../legalAgreementModal/LegalAgreementModal';
import argoHadesClient from '../../utils/ArgoHades';

import './footer.scss';

const Footer: React.FunctionComponent<any> = () => {
  const [showLegalAgreementModal, setShowLegalAgreementModal] = useState<boolean>(false);
  const [siteTermsDocument, setSiteTermsDocument] = useState<string>('');

  useEffect(() => {
    const fetchSiteTerms = async () => {
      const getDocumentResult = await argoHadesClient.getSiteTerms();
      setSiteTermsDocument(getDocumentResult.content);
    };

    fetchSiteTerms();
  }, []);

  return (
    <footer className='argo-footer' data-cy='argo-footer'>
      {showLegalAgreementModal && <LegalAgreementModal onSubmitContinue={() => setShowLegalAgreementModal(false)} siteTermsText={siteTermsDocument} />}
      <ul className='flex-box menu-list'>
        <li className='mr-auto' />
        <li>&copy; {strings.text}</li>
        <li>
          <a href={strings.privacyPolicy.link} className='awsui-text-info-link'>
            {strings.privacyPolicy.text}
          </a>
        </li>
        <li>
          <a className='awsui-text-info-link' onClick={() => setShowLegalAgreementModal(true)}>
            {strings.termsOfUse.text}
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
