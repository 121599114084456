const customCSSPropertiesMap: Record<string, string> = {
  offsetTop: '--awsui-offset-top-ujkcpl',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-ujkcpl',
  contentGapLeft: '--awsui-content-gap-left-ujkcpl',
  contentGapRight: '--awsui-content-gap-right-ujkcpl',
  contentHeight: '--awsui-content-height-ujkcpl',
  defaultMaxContentWidth: '--awsui-default-max-content-width-ujkcpl',
  defaultMinContentWidth: '--awsui-default-min-content-width-ujkcpl',
  footerHeight: '--awsui-footer-height-ujkcpl',
  headerHeight: '--awsui-header-height-ujkcpl',
  layoutWidth: '--awsui-layout-width-ujkcpl',
  mainOffsetLeft: '--awsui-main-offset-left-ujkcpl',
  maxContentWidth: '--awsui-max-content-width-ujkcpl',
  minContentWidth: '--awsui-min-content-width-ujkcpl',
  notificationsHeight: '--awsui-notifications-height-ujkcpl',
  overlapHeight: '--awsui-overlap-height-ujkcpl',
  navigationWidth: '--awsui-navigation-width-ujkcpl',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-ujkcpl',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-ujkcpl',
  splitPanelMinWidth: '--awsui-split-panel-min-width-ujkcpl',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-ujkcpl',
  toolsMaxWidth: '--awsui-tools-max-width-ujkcpl',
  toolsWidth: '--awsui-tools-width-ujkcpl',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-ujkcpl',
  contentScrollMargin: '--awsui-content-scroll-margin-ujkcpl',
};
export default customCSSPropertiesMap;
