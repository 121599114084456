// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { AppLayoutContext } from './context';
import { SplitPanelContext } from '../../internal/context/split-panel-context';
import { useResizeObserver } from '../../internal/hooks/container-queries';
import styles from './styles.css.js';
import testutilStyles from '../test-utils/styles.css.js';
import { AppLayoutProps } from '../interfaces';
import customCssProps from '../../internal/generated/custom-css-properties';

interface LayoutProps {
  children: React.ReactNode;
}

/**
 * The layoutElement ref will be used by the resize observers to calculate the offset from
 * the top and bottom of the viewport based on the header and footer elements. This is to
 * ensure the Layout component minimum height will fill 100% of the viewport less those
 * cumulative heights.
 */
export default function Layout({ children }: LayoutProps) {
  const {
    contentType,
    disableBodyScroll,
    footerSelector,
    hasNotificationsContent,
    headerSelector,
    isNavigationOpen,
    isOverlapDisabled,
    isSplitPanelOpen,
    isToolsOpen,
    layoutElement,
    layoutWidth,
    mainOffsetLeft,
    maxContentWidth,
    minContentWidth,
    navigationHide,
    notificationsHeight,
    splitPanel,
    stickyNotifications,
    toolsHide,
  } = useContext(AppLayoutContext);

  const { position: splitPanelPosition } = useContext(SplitPanelContext);

  /**
   * Query the DOM for the header and footer elements based on the selectors provided
   * by the properties and pass the heights to the custom property definitions.
   */
  const [headerHeight, setHeaderHeight] = useState(0);
  const getHeader = useCallback(() => document.querySelector(headerSelector), [headerSelector]);
  useResizeObserver(getHeader, entry => setHeaderHeight(entry.borderBoxHeight));

  const [footerHeight, setFooterHeight] = useState(0);
  const getFooter = useCallback(() => document.querySelector(footerSelector), [footerSelector]);
  useResizeObserver(getFooter, entry => setFooterHeight(entry.borderBoxHeight));

  // Content gaps on the left and right are used with the minmax function in the CSS grid column definition
  const hasContentGapLeft = getContentGapLeft(isNavigationOpen, navigationHide);
  const hasContentGapRight = getContentGapRight(
    splitPanelPosition,
    isSplitPanelOpen,
    isToolsOpen,
    splitPanel,
    toolsHide
  );

  return (
    <main
      className={clsx(styles.layout, testutilStyles.root)}
      data-content-type={contentType}
      data-disable-body-scroll={disableBodyScroll ? true : false}
      data-has-content-gap-left={hasContentGapLeft ? true : false}
      data-has-content-gap-right={hasContentGapRight ? true : false}
      data-has-max-content-width={maxContentWidth && maxContentWidth > 0 ? true : false}
      data-has-split-panel={splitPanel ? true : false}
      data-has-sticky-notifications={stickyNotifications && hasNotificationsContent ? true : false}
      data-overlap-disabled={isOverlapDisabled ? true : false}
      data-split-panel-position={splitPanelPosition}
      ref={layoutElement}
      style={{
        [customCssProps.headerHeight]: `${headerHeight}px`,
        [customCssProps.footerHeight]: `${footerHeight}px`,
        [customCssProps.layoutWidth]: `${layoutWidth}px`,
        [customCssProps.mainOffsetLeft]: `${mainOffsetLeft}px`,
        [customCssProps.maxContentWidth]: maxContentWidth ? `${maxContentWidth}px` : '',
        [customCssProps.minContentWidth]: minContentWidth ? `${minContentWidth}px` : '',
        [customCssProps.notificationsHeight]: `${notificationsHeight}px`,
      }}
    >
      {children}
    </main>
  );
}

/**
 * When the Navigation and Tools are present the grid definition has the center column
 * touch the first and last columns with no gap. The forms with the circular buttons
 * for Navigation and Tools have internal padding which creates the necessary
 * horizontal space when the drawers are closed. The remaining conditions below
 * determine the necessity of utilizing the content gap left property to create
 * horizontal space between the center column and its adjacent siblings.
 */
function getContentGapRight(
  splitPanelPosition: AppLayoutProps.SplitPanelPosition,
  isSplitPanelOpen?: boolean,
  isToolsOpen?: boolean,
  splitPanel?: React.ReactNode,
  toolsHide?: boolean
) {
  let hasContentGapRight = false;

  // Main is touching the edge of the Layout and needs a content gap
  if (!splitPanel && toolsHide) {
    hasContentGapRight = true;
  }

  // Main is touching the Tools drawer and needs a content gap
  if ((!splitPanel || !isSplitPanelOpen) && !toolsHide && isToolsOpen) {
    hasContentGapRight = true;
  }

  // Main is touching the edge of the Layout and needs a content gap
  if (splitPanel && splitPanelPosition === 'bottom' && (isToolsOpen || toolsHide)) {
    hasContentGapRight = true;
  }

  // Main is touching the Split Panel drawer and needs a content gap
  if (splitPanel && isSplitPanelOpen && splitPanelPosition === 'side') {
    hasContentGapRight = true;
  }

  return hasContentGapRight;
}

/**
 * Additional function to determine whether or not a content gap is needed
 * on the left (see the getContentGapRight function). The same render logic applies
 * regarding the center column touching an adjacent sibling but the only
 * component state that needs to be tracked is the Navigation.
 */
function getContentGapLeft(isNavigationOpen: boolean, navigationHide?: boolean) {
  return isNavigationOpen || navigationHide ? true : false;
}
