// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useContext } from 'react';
import clsx from 'clsx';
import { AppLayoutContext } from './context';
import styles from './styles.css.js';

/**
 * The CSS class 'awsui-context-content-header' needs to be added to the root element so
 * that the design tokens used are overridden with the appropriate values.
 */
export default function Background() {
  const { contentType, hasNotificationsContent, stickyNotifications } = useContext(AppLayoutContext);

  return (
    <div
      className={clsx(styles.background, 'awsui-context-content-header')}
      data-content-type={contentType}
      data-has-notifications-content={hasNotificationsContent ? true : false}
      data-sticky-notifications={stickyNotifications ? true : false}
    />
  );
}
