export const SUB = 'sub';
export const USER_ID_KEY = 'custom:user_id';
export const VENDOR_ID_KEY = 'custom:vendorId';
export const CUSTOM_GROUPS_KEY = 'custom:groups';
export const HASH_KEY = 'at_hash';

export interface SessionServiceCredential {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: number;
}

export class CredentialStorage {
  private customCredentials?: SessionServiceCredential;
  private customGroups: string[];
  private username: string;
  private atHash: string;
  private isExternal: boolean;
  private jwtToken: string;

  constructor() {
    this.customGroups = [];
    this.customCredentials = undefined;
    this.username = 'user';
    this.atHash = '';
    this.isExternal = false;
    this.jwtToken = '';
  }

  _keyExists(payload: any, key: string): boolean {
    return (key in payload &&
      payload[key] !== undefined &&
      payload[key] !== null &&
      typeof payload[key] === 'string' &&
      payload[key].trim().length > 0);
  }

  getAtHash() {
    return this.atHash;
  }

  getCustomGroups() {
    return this.customGroups;
  }

  getCustomCredentials() {
    return this.customCredentials;
  }

  getUserName() {
    return this.username;
  }

  isExternalUser() {
    return this.isExternal;
  }

  getJwtToken() {
    return this.jwtToken;
  }

  setUserCredentials(userCredentials?: SessionServiceCredential) {
    this.customCredentials = userCredentials;
  }

  setUserAttributes(token: string, userDirectoryGroups?: string[]) {
    try {
      this.jwtToken = token;

      const payload = JSON.parse(atob(token.split('.')[1]));

      if (this._keyExists(payload, USER_ID_KEY)) {
        this.username = payload[USER_ID_KEY].trim();
      } else if (this._keyExists(payload, SUB)) {
        this.username = payload[SUB].trim();
      }

      if (this._keyExists(payload, HASH_KEY)) {
        this.atHash = payload[HASH_KEY].trim();
      }

      if (this._keyExists(payload, CUSTOM_GROUPS_KEY)) {
        this.customGroups = payload[CUSTOM_GROUPS_KEY].replace('[', '')
          .replace(']', '')
          .split(',')
          .map((i: string) => i.trim());
      }

      if (this._keyExists(payload, VENDOR_ID_KEY)) {
        this.isExternal = true;
      }

      if (userDirectoryGroups) {
        this.customGroups = userDirectoryGroups;
      }
    } catch (err) {
      return;
    }
  }
}

// Singleton to be shared across WebApp
export const credentialStorage = new CredentialStorage();