// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useContext } from 'react';
import clsx from 'clsx';
import { AppLayoutContext } from './context';
import styles from './styles.css.js';
import testutilStyles from '../test-utils/styles.css.js';

/**
 * The CSS class 'awsui-context-content-header' needs to be added to the root element so
 * that the design tokens used are overridden with the appropriate values.
 */
export default function Notifications() {
  const { ariaLabels, hasNotificationsContent, notifications, notificationsElement, stickyNotifications } =
    useContext(AppLayoutContext);

  if (!notifications) {
    return null;
  }

  return (
    <div
      aria-label={ariaLabels?.notifications ?? undefined}
      className={clsx(styles.notifications, testutilStyles.notifications, 'awsui-context-content-header')}
      data-has-notifications-content={hasNotificationsContent ? true : false}
      data-sticky={stickyNotifications ? 'true' : undefined}
      role="region"
      ref={notificationsElement}
    >
      {notifications}
    </div>
  );
}
